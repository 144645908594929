import React from 'react';
import { Layout } from '../components/shared/Layout';

const VisionPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <div className="container mx-auto">
            <h1>Vision</h1>
            <p>
              Ginger ale has been enjoyed by people across many cultures for
              thousands of years. People drink ginger ale for many reasons. We
              like to believe that the most important reason is its unique and
              delicious taste, which makes it drinkable all day long.
            </p>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default VisionPage;
